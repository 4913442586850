/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Tasks.module.scss';
import { doNavigate, getQueryAutocompleteObjects, getQueryDisplayValue, getSystemConnectionAutocompleteObjects, getSystemConnectionDisplayValue, handleHttpError, i18n, setBreadcrumbEntityName, setDataModified } from '../../utils';
import { getTask, updateTask, createTask, runTask, runTask2 } from '../../services/pages/tasks';
import { FieldEditor } from '../../components/FieldEditor';
import { Button } from '../../components/Button';
import { getConnector } from '../../services/pages/connectors';
import {
  createSystemConnection,
  getSystemConnection,
  updateSystemConnection,
} from '../../services/pages/systemConnections';
import { getEntityQuery } from '../../services/pages/entityQueries';
import { FieldAutocompleteEditor } from '../../components/FieldAutocompleteEditor';
import { setRecentView } from '../../services/pages/recentviews';

import { TaskParamsControl } from '../../components/TaskParamsControl/TaskParamsControl';
import { Loader } from '../../components/Loader';
import { Textarea } from '../../components/Textarea';
import { FieldCheckboxEditor } from '../../components/FieldCheckboxEditor';
import { TaskScheduleEditor } from '../../components/TaskScheduleEditor';

export function Task() {
  const navigate = useNavigate();

  const [, setLoading] = useState(true);
  const [data, setData] = useState({
    entity: {
      name: null,
      description: '',
      query_id: null,
      system_connection_id: null,
      enabled: false,
      is_metadata_task: false,
      schedule_type: '',
      schedule_params: '',
      metadatabases: []
    },
    metadata: { version_id: 0, tags: [] },
  });

  const [showValidation, setShowValidation] = useState(false);
  const [taskId, setTaskId] = useState<string>('');
  const [newTaskData, setNewTaskData] = useState<any>({ schedule_params: '' });
  const [isRunResultLoading, setRunResultLoading] = useState(false);
  const [runResult, setRunResult] = useState('');
  const [allowTaskRun, setAllowTaskRun] = useState<boolean>(true);

  const { id } = useParams();

  useEffect(() => {
    if (!taskId && id) setTaskId(id);
    setDataModified(false);
  }, [id]);

  useEffect(() => {
    if (taskId) {
      setRecentView('task', taskId);
      getTask(taskId)
        .then((json) => {
          setData(json);
          setDataModified(false);
        
          
          setBreadcrumbEntityName(taskId, json.entity.name);

          setLoading(false);
        })
        .catch(handleHttpError);
    }
  }, [taskId]);

  const updateTaskField = (field: string, value: string | boolean) => {
    if (taskId) {
      const d: any = {};
      d[field] = value;
      updateTask(taskId, d)
        .then(() => { setDataModified(false); })
        .catch(handleHttpError);
    } else {
      setShowValidation(true);
      setData((prev: any) => ({ ...prev, entity: { ...prev.entity, [field]: value } }));
      setDataModified(false);
    }
  };

  return (
    <div className={`${styles.page} ${styles.taskPage}`}>
      <div className={styles.mainContent}>
        <div className={styles.title}>{`${i18n('ЗАДАЧА')}`}</div>
        
        {allowTaskRun ? (
        <Button background='outlined-orange' className={styles.btn_run} onClick={() => { setAllowTaskRun(false); runTask2(taskId); }}>Выполнить задачу</Button>
        ) : ('')}
        <div className={styles.general_data}>
          <div className={styles.data_row}>
            <FieldEditor
              isReadOnly={false}
              labelPrefix={i18n('Название')}
              defaultValue={data.entity.name}
              className=""
              layout="separated"
              valueSubmitted={(val) => {
                updateTaskField('name', val.toString());
              }}
              isRequired
              showValidation={showValidation}
            />
          </div>
          <div className={styles.data_row}>
            <FieldAutocompleteEditor
              className=""
              label={i18n('Запрос')}
              defaultValue={data.entity.query_id}
              valueSubmitted={(i) => updateTaskField('query_id', i)}
              getDisplayValue={getQueryDisplayValue}
              getObjects={getQueryAutocompleteObjects}
              isRequired={!data.entity.is_metadata_task}
              showValidation={showValidation}
              artifactType='entity_query'
            />
          </div>
          <div className={styles.data_row}>
            <FieldAutocompleteEditor
                className=""
                label={i18n('Подключение')}
                defaultValue={data.entity.system_connection_id}
                valueSubmitted={(i) => updateTaskField('system_connection_id', i)}
                getDisplayValue={getSystemConnectionDisplayValue}
                getObjects={getSystemConnectionAutocompleteObjects}
                isRequired
                showValidation={showValidation}
                artifactType='system_connection'
              />
          </div>
          <div className={styles.data_row}>
            <FieldCheckboxEditor isReadOnly={false} labelPrefix={i18n('Загрузка метаданных')} defaultValue={data.entity.is_metadata_task} className="" layout="separated"
              valueSubmitted={(val) => {
                updateTaskField('is_metadata_task', val);
              }}
              isRequired={!data.entity.query_id}
              showValidation={showValidation} />
          </div>
          
          <div className={styles.data_row}>
            <FieldCheckboxEditor
              isReadOnly={false}
              labelPrefix={i18n('Включена')}
              defaultValue={data.entity.enabled}
              className=""
              layout="separated"
              valueSubmitted={(val) => {
                updateTaskField('enabled', val);
              }}
              isRequired
              showValidation={showValidation}
            />
          </div>

          <div className={styles.data_row}>
            <FieldEditor 
                isReadOnly
                layout='separated'
                labelPrefix={i18n('Метаданные')}
                defaultValue={data.entity.metadatabases ? data.entity.metadatabases.map((d:any) => (`<a href="/metadata/db/${d.id}">${d.name}</a>`)).join(', ') : ''}
                className={styles.long_input}
                valueSubmitted={(val) => { }}
                onBlur={(val) => { }}
            />
          </div>
          
        </div>

        <div className={styles.general_data}>
          <div className={styles.data_row}>
            <table className={styles.task_params}>
              <tbody>
              <TaskScheduleEditor
                onChanged={(value) => {
                    
                  setNewTaskData((prev: any) => ({
                    ...prev,
                    schedule_type: value.schedule_type,
                    schedule_params: value.schedule_params,
                  }));
                  }}
                defaultScheduleType={data.entity.schedule_type ?? ''}
                defaultScheduleParams={data.entity.schedule_params ?? ''}
                />
              </tbody>
            </table>

            

            <div className={styles.buttons}>
              <Button
                className={styles.btn}
                background="orange"
                onClick={() => {
                  if (taskId) {
                      
                      updateTask(taskId, newTaskData).then(() => { setDataModified(false); }).catch(handleHttpError);
                    
                    } else {
                            const tData = {
                              ...data.entity,
                              ...newTaskData,
                              enabled: true,
                            };
                            createTask(tData)
                              .then((json) => {
                                setDataModified(false);
                                if (json.metadata && json.metadata.id) {
                                  navigate(`/tasks/edit/${encodeURIComponent(json.metadata.id)}`);
                                }
                              })
                              .catch(handleHttpError);
                    
                  }
                }}
              >
                {i18n('Сохранить')}
              </Button>
              <Button
                className={styles.btn}
                background="outlined-orange"
                onClick={() => {
                  navigate('/tasks');
                }}
              >
                {i18n('Отмена')}
              </Button>
              
              <div className={styles.spacer}></div>
              {!data.entity.is_metadata_task && (
              <Button className={styles.btn_check} background='none' onClick={() => {
                setRunResultLoading(true);
                runTask(taskId).then(res => {
                  setRunResultLoading(false);
                  setRunResult(res);
                }).catch(err => { setRunResultLoading(false); handleHttpError(err); })
              }}>{i18n('Проверка выполнения')}</Button>
              )}
            </div>
            <div className={styles.run_result}>
              {isRunResultLoading ? (<Loader className={styles.loader} />) : (runResult && (<Textarea value={runResult} />))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
